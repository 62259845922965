<template>
  <div>
    <div class="row d-lg-none">
      <div class="col-lg-6 order-1 orders-sm-0">
        <h3 class="mb-2">
          <span v-text="user.first_name"></span>, zacznijmy naukę:
        </h3>
        <div class="row">
          <div class="col-sm-6">
            <b-link :to="{name:'my-courses'}">
              <div class="mb-2">
                <b-img
                    alt="Kursy chemmaster"
                    class="card-img-top"
                    :src="courseIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-6">
            <b-link :to="{name:'discounts'}">
              <div class="mb-2">
                <b-img
                    alt="Kursy chemmaster"
                    class="card-img-top"
                    :src="discountIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-6">
            <b-link :to="{name:'orders'}">
              <div class="mb-2">
                <b-img
                    alt="Zamówienia chemmaster"
                    class="card-img-top"
                    :src="ordersIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-6">
            <b-link :to="{name:'settings'}">
              <div class="mb-2">
                <b-img
                    alt="Ustawienia chemmaster"
                    class="card-img-top"
                    :src="settingsIcon"
                />
              </div>
            </b-link>
          </div>
        </div>
      </div>
      <div class="col-lg-6 order-0 order-sm-1">
        <h3 class="mb-2">
          Cytat na dziś:
        </h3>
        <Quotation :quoatation-img="quoatationImg"
                   :quoatation-mobile-img="quoatationMobileImg"
                   :quotation="quotation"/>
        <div>
          <b-link :to="{name:'shop'}">
            <div class="mb-2">
              <b-img
                  alt="Sklep"
                  class="card-img-top d-none d-lg-inline-block"
                  :src="shopBanner"
              />
              <b-img
                  alt="Sklep"
                  class="card-img-top d-lg-none"
                  :src="shopBannerMobile"
              />
            </div>
          </b-link>
        </div>
      </div>
    </div>
    <div class="row d-none d-lg-flex">
      <div class="col-lg-12">
        <h3 class="mb-2">
          <span v-text="user.first_name"></span>, zacznijmy naukę:
        </h3>
        <div class="row">
          <div class="col-sm-3">
            <b-link :to="{name:'my-courses'}">
              <div class="mb-2">
                <b-img
                    alt="Kursy chemmaster"
                    class="card-img-top"
                    :src="courseIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-3">
            <b-link :to="{name:'discounts'}">
              <div class="mb-2">
                <b-img
                    alt="Kursy chemmaster"
                    class="card-img-top"
                    :src="discountIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-3">
            <b-link :to="{name:'orders'}">
              <div class="mb-2">
                <b-img
                    alt="Zamówienia chemmaster"
                    class="card-img-top"
                    :src="ordersIcon"
                />
              </div>
            </b-link>
          </div>
          <div class="col-sm-3">
            <b-link :to="{name:'settings'}">
              <div class="mb-2">
                <b-img
                    alt="Ustawienia chemmaster"
                    class="card-img-top"
                    :src="settingsIcon"
                />
              </div>
            </b-link>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h3 class="mb-2">
          Cytat na dziś:
        </h3>
      </div>
      <div class="col-lg-6">
        <div class="quotation" :style="{ backgroundImage: `url(${quoatationImg})` }">
          <p><strong>{{ quotation.cytat }}</strong></p>
          <p class="font-italic text-right">{{ quotation.autor }}</p>
        </div>
        <div class="quotation-mobile" :style="{ backgroundImage: `url(${quoatationMobileImg})` }">
          <p><strong>{{ quotation.cytat }}</strong></p>
          <p class="font-italic text-right">{{ quotation.autor }}</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <b-link :to="{name:'shop'}">
            <div class="mb-2">
              <b-img
                  alt="Sklep"
                  class="card-img-top d-none d-lg-inline-block"
                  :src="shopBanner"
              />
              <b-img
                  alt="Sklep"
                  class="card-img-top d-lg-none"
                  :src="shopBannerMobile"
              />
            </div>
          </b-link>
        </div>
      </div>
    </div>

    <div>
      <b-modal
          ref="newsletter-modal"
          no-close-on-backdrop
          hide-footer
          size="lg"
          title="Informacja"
      >
        <div class="d-block" style="position: relative;">
          <img :src="kipBanner" alt="KIP 2024" style="width: 100%;" />
          <span v-text="kipDiscountCode" id="kipDiscountCode"></span>
          <a href="https://chemmaster.pl/powtorka-przed-matura-z-chemii/" id="kipLink" target="_blank">&nbsp;</a>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {BImg, BLink, BModal} from 'bootstrap-vue'
import Quotation from "@/views/Quotation.vue";

export default {
  components: {
    Quotation,
    BModal,
    BImg, BLink,
    
  },
  data() {
    return {
      introImg: require('@/assets/images/chemmaster/chemmaster-pani-sonia-witaj.png'),
      courseIcon: require('@/assets/images/chemmaster/dashboard/courses2.svg'),
      discountIcon: require('@/assets/images/chemmaster/dashboard/discounts2.svg'),
      ordersIcon: require('@/assets/images/chemmaster/dashboard/orders2.svg'),
      settingsIcon: require('@/assets/images/chemmaster/dashboard/settings2.svg'),
      quoatationImg: require('@/assets/images/chemmaster/dashboard/quotation.png'),
      quoatationMobileImg: require('@/assets/images/chemmaster/dashboard/quotation-mobile.png'),
      shopBanner: require('@/assets/images/chemmaster/dashboard/banner-shop.svg'),
      shopBannerMobile: require('@/assets/images/chemmaster/dashboard/banner-shop-mobile.svg'),
      shopBannerBF: require('@/assets/images/chemmaster/BF/banner-dashboard.png'),
      shopBannerMobileBF: require('@/assets/images/chemmaster/BF/banner-dashboard-mobile.png'),
      kipBanner: require('@/assets/images/chemmaster/banners/popup.svg'),
      user: null,
      courses: [],
      quotation: '',
      discounts: [],
      kipDiscountCode: '',
    }
  },
  created() {
    this.fetchMyData()
    this.fetchCourses()
    this.settings()
   // this.fetchDiscounts()
  },
  computed: {
    threeCourses() {
      return this.courses.slice(0, 3)
    },
  },
  methods: {
    settings() {
      this.$http.get(`${this.$store.state.apiDomain}/api/settings`).then(response => {
        if(response.data.status === 'ok') {
          this.quotation = response.data.quotation
        }
      })
    },
    fetchDiscounts() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/discounts`).then(response => {
        this.discounts = response.data.discounts
        //this.findKIPDiscount()
      })
    },
    findKIPDiscount() {
      // eslint-disable-next-line array-callback-return
      const kipDiscounts = this.discounts.filter(discount => discount.isKIP)

      if (kipDiscounts.length > 0) {
        this.kipDiscountCode = kipDiscounts[0].discount_value + "%"
        this.showNewsletter()
      } else {
        this.kipDiscountCode = false
      }
    },
    showNewsletter() {
      const modalShown = localStorage.getItem(`modal_showd_kip`)
      const maxAge = 60000 * 60 * 24 // 1 day
      const expiryDate = Date.now() + maxAge

      if (this.kipDiscountCode && modalShown <= Date.now()) {
    //  if (this.kipDiscountCode) {
        this.$refs['newsletter-modal'].show()
        localStorage.setItem(`modal_showd_kip`, expiryDate)
      }
    },
    fetchMyData() {
      this.$http.get(`${this.$store.state.apiDomain}/api/me`).then(response => {
        this.user = response.data.user
        this.user.first_name = this.user.name.split(' ')[0] ?? ''
        this.user.last_name = this.user.name.split(' ')[1] ?? ''
      })
    },
    fetchCourses() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/courses`).then(response => {
        this.courses = response.data.courses
      })
    },
  }
}
</script>

<style lang="scss">
  html .breadcrumbs-top .content-header-title{
    display: none !important;
  }
  #kipDiscountCode{
    position: absolute;
    top: 49%;
    left: 31%;
    font-size: 28px;
    font-weight: bold;
    color: #101010;
    transform: rotate(0.045turn);
    @media screen and (max-width: 768px){
      left: 30%;
      font-size: 22px;
    }
  }
  #kipLink{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }
  .quotation, .quotation-mobile{
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding-left: 230px;
    padding-right: 30px;
    padding-top: 75px;
    margin-bottom: 30px;
    font-size: 13px;
  }
  @media screen and (max-width: 992px) {
    .quotation{
      display: none;
    }
    .quotation-mobile{
      display: block;
      padding-left: 160px;
      padding-right: 10px;
      font-size: 12px;
      padding-top: 80px;
      min-height: 220px;
    }
  }
  @media screen and (min-width: 992px) {
    .quotation{
      display: block;
      aspect-ratio: 2.66 / 1;
    }
    .quotation-mobile{
      display: none;
    }
  }
  h4{
    color: #20ad95;
  }
</style>
